@import url("https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Manjari", sans-serif;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
  text-decoration: none !important;
}

.wrapper {
  background-image: linear-gradient(to bottom right, #0f0c38, #211c5c);
  color: #fff;
  padding-top: 20px;
  padding-bottom: 120px;
}

.btn {
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  background: #6724fd;
  border-radius: 4px;
  margin: 20px;
}
.btn:hover {
  background: #fff;
  color: #6724fd;
}
